import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import SEO from '../../../shared/components/atoms/seo/seo'
import MainBanner from '../../molecules/main-banner/main-banner'
import SectionBlog from '../../molecules/section-blog/section-blog'
import SectionProducts from '../../molecules/section-products/section-products'
import SectionResponsability from '../../molecules/section-responsability/section-responsability'
import './home-content.scss'

const HomeContent = (props) => {

  const titleHomeResult = useStaticQuery(
    graphql`
      query TitleHomeQuery {
        allContentfulPetysHome {
          nodes {
            tituloPrincipal
            metaDescripcion {
              metaDescripcion
            }
            metaTitulo
            slug
          }
        }
      }
    `
  );

  const { setModalActive } = props
  const homeInfo = titleHomeResult.allContentfulPetysHome.nodes[0]

  return (
    <div className="f-home-container">
      <SEO
        title={homeInfo.metaTitulo ? homeInfo.metaTitulo : ''}
        description={homeInfo.metaDescripcion ? homeInfo?.metaDescripcion?.metaDescripcion : ''}
        lang="ES"
      />
      <div className="main-banner-container">
        <MainBanner />
      </div>
      <h1 className="f-home-title">
        <i className="icon-huella"></i>
        {homeInfo.tituloPrincipal}
        <i className="icon-huella"></i>
      </h1>
      <div className="section-product-container">
        <SectionProducts />
      </div>
      <div className="section-blog-container">
        <SectionBlog />
      </div>
      <div className="section-responsability">
        <SectionResponsability />
      </div>
    </div>
  )
}

export default HomeContent;