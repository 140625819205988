import React from "react";
import Layout from "../shared/components/organisms/layout/layout";
import HomeContent from "../components/organisms/home-content/home-content";
import '../shared/styles/index.scss';

// import SEO from "../shared/components/atoms/seo/seo"

const IndexPage = () => {

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);

  return (
    <Layout activeMenu={0} modalActive={modalActive}>
      <HomeContent setModalActive={setModalActive} />
    </Layout>
  )
}

export default IndexPage
