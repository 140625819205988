import React, { useRef } from 'react'
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import Button from '../../../shared/components/atoms/button/button'
import CardProduct from '../../../shared/components/atoms/card-product/card-product'
import useProduct from '../../../shared/hooks/useProduct'
import homeLink from '../../../shared/utils/siteUrl'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './section-products.scss'

const SectionProducts = () => {

  const productFeatureInfo = useStaticQuery(
    graphql`
        query ProductsFeaturedQuery {
            allContentfulPetysHome {
              nodes {
                botonProductosDestacados
                tituloProductosDestacados
                productosDestacados {
                  idDelProducto
                  nombreDelProducto
                  titulo
                  slug
                  reviewCount
                  ratingValue
                  queTipoDeProductoEs
                  imagenDelProducto {
                    description
                    title
                    file {
                      url
                    }
                  }
                }
                descripcionProductosDestacados {
                  descripcionProductosDestacados
                }
              }
            }
          }
        `
  );

  const slider = useRef(null);
  const productSectionInfo = productFeatureInfo.allContentfulPetysHome.nodes[0];
  const [getFilterNewsProduct] = useProduct()

  const dataLayerPush = (label = "Ir a productos") => {
    window.location.href = homeLink + 'productos'
  }

  const listItems = getFilterNewsProduct(productSectionInfo.productosDestacados).slice(0, 8).map((product, key) => {
    return <CardProduct className="f-home-cards" product={product} key={key} onClick={() => dataLayerPush(product?.nombreDelProducto)}></CardProduct>
  })

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div className={"f-slider-control-next"} onClick={() => slider.current.slickNext()}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div className={"f-slider-control-prev"} onClick={() => slider.current.slickPrev()}>
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: listItems.length > 3,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
          centerPadding: "60px 0 0",
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: "60px 0 0",
          infinite: true,
          dots: true,
          arrows: false,
          nextArrow: null,
          prevArrow: null,
        }
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "60px 0 0",
          infinite: true,
          dots: true,
          arrows: false,
        }
      },
    ]
  };

  return (
    <div className="f-section-product">
      <div className="f-center-content">
        <h2 className="f-title f-product-title">{productSectionInfo.tituloProductosDestacados}</h2>
        <Button disabled={false} className={"f-primary-purple f-product-button"} title={productSectionInfo.botonProductosDestacados} href={"productos/"} onClick={dataLayerPush} />
        <p className="f-text-regular f-product-home-description">{productSectionInfo.descripcionProductosDestacados.descripcionProductosDestacados}</p>
        <Slider {...settings} ref={slider} className="f-slider-product">
          {listItems}
        </Slider>
      </div>
    </div>
  )
}

export default SectionProducts
