import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import Button from '../../../shared/components/atoms/button/button'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './section-responsability.scss'

const SectionProducts = () => {
  const responsabilitySectionInfo = useStaticQuery(
    graphql`
      query ResponsabilityQuery {
        allContentfulPetysHome {
          nodes {
            footerTituloResponsabilidadSocial
            botonResponsabilidadSocial
            footerRedireccionResponsabilidadSocial
            descripcionResponsabilidadSocial {
              descripcionResponsabilidadSocial
            }
            imagenResponsabilidadSocial {
              description
              title
              file {
                url
              }
            }
          }
        }
      }
    `
  );

  const responsabilityInfo = responsabilitySectionInfo.allContentfulPetysHome.nodes[0];


  return (
    <div className="f-section-responsability">
      <img src={responsabilityInfo.imagenResponsabilidadSocial.file.url} className="f-background-img" alt={responsabilityInfo.imagenResponsabilidadSocial.description} />
      <div className="f-content-center">
        <h2 className="f-title f-responsability-title">{responsabilityInfo.footerTituloResponsabilidadSocial}</h2>
        <p className="f-text-regular f-responsability-description">{responsabilityInfo.descripcionResponsabilidadSocial.descripcionResponsabilidadSocial}</p>
        <Button className={"f-primary-purple f-responsability-button"} title={responsabilityInfo.botonResponsabilidadSocial} href={responsabilityInfo.footerRedireccionResponsabilidadSocial} />
      </div>
    </div>
  )
}

export default SectionProducts
