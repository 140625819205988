import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import CardArticle from "../../../shared/components/atoms/card-article/card-article";
import homeLink from '../../../shared/utils/siteUrl'
import useMobile from '../../../shared/hooks/useMobile'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './section-blog.scss'

const SectionProducts = () => {
  const BlogInfo = useStaticQuery(
    graphql`
    query BlogFeaturedQuery {
      allContentfulPetysHome {
        nodes {
          tituloBlogDeExpertos
          tituloEntradasRecientes
          descripcionBlogDeExpertos {
            descripcionBlogDeExpertos
          }
          categoriasBlogDeExpertos {
            nombreCategoria
            redireccionCategoria
            imagenCategoria {
              description
              title
              file {
                url
              }
            }
          }
        }
      }
      allContentfulPetysArticulos(sort: {fields: fechaDePublicacionDelArticulo, order: DESC}) {
        nodes {
          contentful_id
          nombreDelArticulo
          slugDelArticulo
          autorDelArticulo
          fechaDePublicacionDelArticulo(formatString: "DD/MM/YYYY")
          categoriasDelArticulo {
            nombreCategoria
            redireccionCategoria
          }
          descripcionCortaDelArticulo {
            descripcionCortaDelArticulo
          }
          imagenDePrevisualizacionDelArticulo {
            description
            title
            file {
              url
            }
          }
        }
      }
    }
    `
  );

  const BlogSectionInfo = BlogInfo.allContentfulPetysHome.nodes[0];
  const categoryBlogDeExpertos = BlogInfo.allContentfulPetysHome.nodes[0].categoriasBlogDeExpertos;
  const recentsArticles = BlogInfo.allContentfulPetysArticulos.nodes.slice(0, 3)

  const isMobile = useMobile();

  return (
    <div className="f-section-blog">
      <div className="f-center-content">
        <h2 className="f-blog-title">{BlogSectionInfo.tituloBlogDeExpertos}</h2>
        <p className="f-text-regular f-blog-description">{BlogSectionInfo?.descripcionBlogDeExpertos?.descripcionBlogDeExpertos}</p>
        <div className="f-blog-category">
          {
            categoryBlogDeExpertos.map((category, key) => {
              return (
                <a href={homeLink + "blog-de-expertos/" + (category?.redireccionCategoria)} className="f-blog-category-img" key={key}>
                  <img src={category?.imagenCategoria?.file?.url} alt={category?.imagenCategoria?.description} />
                </a>
              )
            })
          }
        </div>
        <div className="f-blog-recents-title">
          <h3 className="f-title">Entradas recientes</h3>
          <span className="f-recents-separator"></span>
          <a href={homeLink + "blog-de-expertos/"} className="f-recent-show-all">Ver todos</a>
        </div>
      </div>
      <div className="f-blog-recents-entries">
        {
          recentsArticles.map((article, key) => {
            return (
              <CardArticle className="f-card-blog" article={article} key={key} onClick={() => dataLayerPush(article?.nombreDelArticulo, "Home :: Entradas recientes")} />
            )
          })
        }
      </div>
    </div>
  )
}

export default SectionProducts
