import { graphql, useStaticQuery } from "gatsby";
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Button from '../../../shared/components/atoms/button/button';
import useMobile from '../../../shared/hooks/useMobile';
import './main-banner.scss';

const MainBanner = () => {

  const bannersInfoResult = useStaticQuery(
    graphql`
      query BannersHomeQuery {
        allContentfulPetysHome {
          nodes {
            banners {
              titulo
              tituloBanner
              botonBanner
              redireccionBanner
              banners {
                title
                description
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const isMobile = useMobile();
  const bannersInfo = bannersInfoResult.allContentfulPetysHome.nodes[0].banners

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
  };

  const listItems = bannersInfo.slice(0, 5).map((slide, key) => {
    return (
      <div key={key}>
        <div className="f-slider-pos">
          <img className="f-slider-image" src={slide.banners[(isMobile ? 1 : 0)].file.url} alt={slide.banners[(isMobile ? 1 : 0)].description} />
          {slide.tituloBanner && <p className="f-slider-title">{slide.tituloBanner}</p>}
          {slide.botonBanner && <Button className={"f-primary-purple f-slider-button"} title={slide.botonBanner} onClick={() => 
            {
              window.location.href = slide.redireccionBanner
            }}/>}
        </div>
      </div>
    )
  })

  return (
    <>
      <Slider {...settings} className="f-slider-conatiner">
        {listItems}
      </Slider>
    </>
  )
}

export default MainBanner;